import React, { ComponentProps } from 'react'
import Subtitle from '../Typography/Subtitle'
import styled from 'styled-components'
import { rem } from 'polished'
import { TextBlockIcon, TextBlockProps, TextBlockStyle } from './TextBlock'
import cn from 'clsx'

const TextBlock = styled(Subtitle)`
  ${TextBlockStyle}

  &.variant-subtitle1 {
    --text-block-gap: ${rem(8)};
  }

  &.variant-subtitle2,
  &.variant-subtitle3 {
    --text-block-gap: ${rem(4)};
  }
`

interface Props extends Omit<ComponentProps<typeof Subtitle>, 'as' | 'forwardedAs'>, TextBlockProps {
}

function SubtitleTextBlock(props: Props) {
  const {
    children,
    endIcon = null,
    startIcon = null,
    fit,
    align,
    tabletAlign,
    variant,
    className,
    format,
    lineClamp,
    wrap,
    colour,
    ...rest
  } = props

  return <TextBlock
    {...rest}
    colour={colour}
    variant={variant}
    className={cn(
      className,
      fit ? `fit-${fit}` : undefined,
      align ? `align-${align}` : undefined,
      tabletAlign ? `align-on-tablet-${tabletAlign}` : undefined,
    )}
  >
    {!!startIcon && <TextBlockIcon>{startIcon}</TextBlockIcon>}
    <Subtitle
      variant={variant}
      format={format}
      lineClamp={lineClamp}
      wrap={wrap}
      align={align}
      tabletAlign={tabletAlign}
      colour={colour}
    >
      {children}
    </Subtitle>
    {!!endIcon && <TextBlockIcon>{endIcon}</TextBlockIcon>}
  </TextBlock>
}

export default SubtitleTextBlock
